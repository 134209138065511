
if (!window.POLKURIER) {
    window.POLKURIER = {};
}


class FrontApp {

    constructor() {
        this.mapJsUrl = 'https://maps.polkurier.pl/assets/dist/points-map.bundle.js?v=f4be7495-ccdc-448e-82c7-aab9dd4788bb';
        this.mapCssUrl = 'https://maps.polkurier.pl/assets/dist/points-map.css?v=f89e1aef-33e9-4ee3-8fc9-93c0da770bc7';
        this.additionalFieldsIds = POLKURIER.additionalFieldsIds ? JSON.parse(POLKURIER.additionalFieldsIds) : null;
        this.shippingsMap = POLKURIER.mapShippingsMap ? JSON.parse(POLKURIER.mapShippingsMap) : null;

        if (!this.additionalFieldsIds) {
            throw 'Aplikacja nie została zainstalowana poprawnie.';
        }

        if (!this.shippingsMap) {
            throw 'Nie skonfigurowano sposobów dostawy. Skonfiguruj w Aplikacje > Moje Aplikacje > Polkurier.pl > Ustawienia';
        }

        this.allowedShippingsIds = Object.keys(this.shippingsMap).map(id => Number(id));
    }

    isShoperBasket() {
        return window.shoper && window.shoper.baskethandler;
    }

    getShoperBasketStep() {
        if (this.isShoperBasket()) {
            const options = window.shoper.baskethandler.options || {};
            return options.step;
        }
    }

    getAdditionalFieldId(fieldName) {
        return this.additionalFieldsIds[fieldName];
    }

    getAdditionalFieldValue(fieldName) {
        const id = this.getAdditionalFieldId(fieldName);
        if (!id) {
            return void 0;
        }
        const input = document.getElementById(`additional_${id}`);
        return input ? input.value : null;
    }

    setAdditionalFieldValue(fieldName, value) {
        const id = this.getAdditionalFieldId(fieldName);
        if (!id) {
            return void 0;
        }
        const input = document.getElementById(`additional_${id}`);
        if (input) {
            input.value = value;
        }
        return this;
    }

    getAddressFieldValue(fieldName) {
        const input = document.getElementById(`input_${fieldName}`);
        return input ? input.value : '';
    }

    isDifferentAddress() {
        const input = document.getElementById('address_different');
        return input ? input.checked : false;
    }

    isCod() {
        const input = document.getElementById('is_cod');
        return input ? Number(input.value) === 1 : false;
    }

    addScript(src) {
        const script = document.createElement('script');
        script.src = src;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    addStyle(src) {
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = src;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    showMap(options = {}) {
        const functions = ['collect'];
        if (this.isCod()) {
            functions.push('cod');
        }

        let searchQuery = this.getAdditionalFieldValue('order_point_id');
        if (!searchQuery) {
            searchQuery = this.getAddressFieldValue(this.isDifferentAddress() ? 'zip2' : 'zip');
        }

        const map = new POLKURIER.PointsMap({
            ...options,
            functions,
            searchQuery,
            token: POLKURIER.mapToken,
        });
        map.onConfirm(point => {
            const providerName = typeof map.getProviderName === 'function' ? map.getProviderName(point.provider) : '';
            this.setAdditionalFieldValue('order_point_id', point.id);
            this.setAdditionalFieldValue('order_point_provider', point.provider);
            this.setAdditionalFieldValue('order_point_name', `${point.street}, ${point.city} ${point.zip}`)
            this.setAdditionalFieldValue('order_point_description', `${providerName}, ${point.description}`);
            document.getElementById('pk-map-point-container').innerHTML = `
                ${providerName} <strong>${point.id}</strong><br>
                ${point.street}, ${point.city} ${point.zip}
                ${point.description ? `<br>(${point.description})` : ''}
            `;
            map.closeMapDialog();
        });
        map.showMapDialog();
    }

    initialize() {
        if (this.isInitialized || !this.isShoperBasket() || !window.frontAPI) {
            return;
        }
        switch (this.getShoperBasketStep()) {
            case 2:
                this.initializeForStep2();

                break;
            case 3:
                this.initializeForStep3();
                break;
        }
        this.isInitialized = true;
    }

    initializeForStep2() {
        this.addScript(this.mapJsUrl);
        this.addStyle(this.mapCssUrl);

        frontAPI.getBasketInfo(data => {
            let selectedShipping;
            for (let shipping of data.shippings || []) {
                if (shipping.selected === true && this.allowedShippingsIds.indexOf(shipping.shipping_id) !== -1) {
                    selectedShipping = shipping;
                    break;
                }
            }
            if (selectedShipping) {
                const selectedPointProvider = this.getAdditionalFieldValue('order_point_provider');
                const selectedShippingProviders = this.shippingsMap[selectedShipping.shipping_id];
                if (selectedPointProvider && selectedShippingProviders.indexOf(selectedPointProvider) === -1) {
                    this.setAdditionalFieldValue('order_point_id', '');
                    this.setAdditionalFieldValue('order_point_provider', '');
                    this.setAdditionalFieldValue('order_point_name', '');
                    this.setAdditionalFieldValue('order_point_description', '');
                }
                this.renderStep2({selectedShipping});
            }
        });
    }

    renderStep2({selectedShipping}) {
        const table = document.querySelector(shoper.baskethandler.options.selectors.addressfieldscontainer + ' tbody');
        const tr = document.createElement('tr');
        const selectedId = this.getAdditionalFieldValue('order_point_id');
        const selectedDescription = this.getAdditionalFieldValue('order_point_description');
        tr.innerHTML = `
            <td class="label">
                <em class="color">*</em>
                Punkt odbioru
            </td>
            <td class="input">
                <div id="pk-map-point-container" style="padding: 1rem;">
                    ${selectedId ? `
                        <strong>${selectedId}</strong><br>
                        ${this.getAdditionalFieldValue('order_point_name')}
                        ${selectedDescription ? `<br>${selectedDescription}` : ''}
                    ` : ''}
                </div>
                <button type="button" id="pk-map-button" class="btn-red btn">Wybierz punkt</button>
            </td>
        `;
        table.appendChild(tr);
        document.getElementById('pk-map-button').addEventListener('click', () => {
            this.showMap({
                providers: this.shippingsMap[selectedShipping.shipping_id],
            });
        });
        document.querySelector('#box_basketaddress button[name="button2"]').addEventListener('click', event => {
            if (!this.getAdditionalFieldValue('order_point_id')) {
                event.preventDefault();
                alert('Należy wybrać punkt odbioru');
                return false;
            }
        });
    }

    initializeForStep3() {
        frontAPI.getBasketInfo(data => {
            const additionalFields = {};
            if (data.additional_fields) {
                let id, field;
                for (field in this.additionalFieldsIds) {
                    if (this.additionalFieldsIds.hasOwnProperty(field)) {
                        id = this.additionalFieldsIds[field];
                        additionalFields[field] = String(data.additional_fields[id]);
                    }
                }
            }

            const li = document.createElement('li');
            li.innerHTML = `Punkt odbioru:
                ${additionalFields.order_point_id ? `
                    <strong>${additionalFields.order_point_id}</strong>,
                    <small>
                        ${additionalFields.order_point_name}
                        ${additionalFields.order_point_description ? `(${additionalFields.order_point_description})</small>` : ''}
                    </small>
                ` : '-'}

            `;
            document.querySelector('.address.information > ul').appendChild(li);
        });
    }

}

try {
    window.POLKURIER.frontApp = new FrontApp();
} catch (e) {
    console.error('Nie można uruchomić aplikacji Polkurier', e);
}

const initializeApplication = () => {
    if (window.POLKURIER.frontApp) {
        try {
            window.POLKURIER.frontApp.initialize();
        } catch (e) {
            console.error('Nie można zainicjować aplikacji Polkurier', e);
        }
    }
};

document.addEventListener("DOMContentLoaded", initializeApplication);
document.addEventListener('frontAPIReady', initializeApplication);
